<template>
<div class = 'modal' v-show = 'showModal'>
      <div class = 'modal-content'>
        <div class = 'modal-header'>
          <h3 class = 'modal-title'>
            Welcome to Emoji Splitter
          </h3>
          
        </div>
        <div class = 'modal-body'>
          You can use this tool to split a composite emoji into its component parts.
          <br>
          Please note that this tool may not give accurate results if you enter more than one emoji.
          <br>
          <h4 class = 'modal-title'>
            Usage Example
          </h4>
          <img src='./assets/example.png' class = 'centered_image_medium'>
        </div>
        <button type = 'button' class = 'full_width_button' @click = 'showModal = false'>
            <span aria-hidden = 'true'>Ok</span>
          </button>
          
       
      </div>
    </div>
  <div class = 'main'>
    
    <div class = 'title'>
      <h1>{{ title }}</h1>
    </div>
    <div class = 'input'>
      <input placeholder = 'Enter an emoji' type = 'text' v-model = 'input' @keyup.enter = 'addItem(input)' />
      <button class = 'splitButton' @click = 'addItem(input)'>Split</button>
    </div>

    <div class = 'result_tiles'>
      <div class = 'largeTile' v-for = 'item in result' :key='item'>
        <div class = 'item'>{{ item }}</div>
        
      </div>

      </div>
    </div>

</template>

<script>



export default {
  name: 'App',
  components: {
    
  },
  data: function () {
    return {
      title: 'Emoji Splitter',
      result: [],
      showModal: true
    }
  },
  methods: {
    addItem: function (input) {      
      let disallowedCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789$&@!#%^*()-_=+{}|\\:;<>,.?/~` ';
      let res = [...input].filter(element=>{
        return !disallowedCharacters.includes(element);
      });

      

        this.result = res.filter((element, index) => {
          return index % 2 === 0;
        })
        
      
      
      
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  transition: all 0.3s ease;

}
.main{
  display:inline-block;
}

.centered_image_medium{
  display:block;
  margin:auto;
  width:50%;
}
.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
.modal-content{
  background-color:#fefefe;
  margin:auto;
  padding:20px;
  border:1px solid #888;
  width:80%;
  border-radius:10px;
  transition: all 0.3s ease;

}

.full_width_button{
  width:100%;
  border: none;
  border-radius:10px;
  padding:10px;
  transition: all 0.3s ease;
}
.splitButton{

  background-color: #2c3e50;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: 10px;
  transition: all 0.3s ease;

}
input{
  border: none;
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: 10px;
}
input:focus{
  border:none;
  outline: none;
}
.splitButton:hover{
  background-color:green
}

.full_width_button:hover{
  background-color:#2c3e50;
  color:white;
}
.largeTile{
  display: inline-block;
  width: 100%;
  height: 100px;
  background-color: #ecf0f1;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
  box-shadow: 0px 0px 5px #bdc3c7;
}
.item{
  display: inline-block;
  width: 50%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 100px;
  font-size: 250%;
}

</style>
